:root {
  --brand: #dd3433;
  --white: #fff;
  --grey-light: #e9e9ea;
  --grey: #aaa;
  --dark: #333;
  //swapped if changing darkmode preference:
  --main-bg: #fff;
  --main-fg: #333;
}
[data-theme='dark'] {
  --main-bg: #333;
  --main-fg: #fff;
  a,
  a:visited {
    color: var(--main-fg); //now white
  }
}

/* super lightweight custom reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}
video {
  display: inline-block;
}
img {
  border-style: none;
}
/* END super lightweight custom reset */

html {
  font-size: 10px;
}
body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.8rem;
  line-height: 1.8em;

  background: var(--main-bg);
  color: var(--main-fg);
}
main {
  //Needs to be set or mobile chrome (pixel 2) was setting it as dark (due to OS setting)
  background: var(--main-bg);
  transition: background 0.25s;
}
p {
  margin: 0 0 1em;
}
h1,
h2,
h3 {
  font-weight: bold;
}
h1 {
  font-size: 5rem;
  line-height: 1em;
  font-weight: normal;
  margin: 0 0 0.7em;
}
h2 {
  font-size: 3rem;
  margin: 0 0 0.7em;
}
.text-big {
  font-size: 2.5rem;
}
.text-sm {
  font-size: 1.2rem;
  line-height: 1.5em;
}
// .hero-img {
//   display: none;
// }
.hero-video-wrap {
  transform: translateX(-30px);
}
/* HELPERS */
.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.col-2 {
  width: 100%;
}
.flex-50-wrap {
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex-50 {
  width: 100%;
}
.als-center {
  align-self: center;
}

.flex-column {
  flex-direction: column;
}
.text-center {
  text-align: center;
}
.text-center-xs {
  text-align: center;
}
.pt-xl {
  padding-top: 4rem;
}
.mt-xl {
  margin-top: 4rem;
}
.mb-xl {
  margin-bottom: 4rem;
}
.mb-xxl {
  margin-bottom: 7rem;
}
.u-accessible-text {
  position: absolute;
  height: 1px;
  width: 1px;
  left: -9999px;
}
/* END HELPERS */
.btn,
.btn:visited {
  display: inline-block;
  padding: 0.8rem 1.2rem;
  text-decoration: none;
  background: var(--brand);
  color: var(--white);
  border-radius: 4px;
  margin-bottom: 10px;
}
.btn:hover {
  text-decoration: underline;
}
a,
a:visited {
  color: var(--brand);
}

.nav {
  font-size: 1.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
  a {
    text-decoration: none;
    margin: 0 0.5em;
    line-height: 50px;
    font-size: 1.8rem;
  }
}
.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1.5em;
}
.narrow {
  max-width: 700px;
}

.intro-wrap {
  overflow: hidden;
  background: var(--brand);
  color: var(--white);
  margin-bottom: 40px;
  a {
    color: var(--white);
  }
}
// .bg-tgs {
//   background: #014bba;
// }
.bg-ob {
  background: #2c6422;
}
.bg-kitomba {
  background: #005c93;
}
.home-intro {
  flex-direction: column;
}
.intro-text {
  align-self: center;
  font-size: 2.5rem;
  padding: 2em 0 1.5em;
}
.intro-img {
  align-self: center;
  img {
    width: 375px;
    display: block;
  }
}
.endCTA {
  margin-top: 3rem;
  margin-bottom: 3rem;
  h4 {
    padding-top: 3rem;
    border-top: 1px solid #eee;
  }
}

.hero-video {
  bottom: 0;
  background-image: url('./img/iphone-frame.png');
  background-repeat: no-repeat;
  background-position: 0;
  background-size: 100%;
  width: 365px;
  height: 568px;
  position: relative;
  margin: 0 auto;
}
.hero-video video {
  width: 270px;
  position: absolute;
  bottom: -6px;
  right: 47px;
}

a.card-wrap {
  text-decoration: none;
  color: var(--main-fg);
  margin: 40px 0;
}
.card-img {
  margin-bottom: 1.5rem;
  img {
    width: 100%;
    display: block;
  }
}
.card__heading {
  margin-top: 0;
}
.fake-card-link {
  text-decoration: underline;
}
.content-blob {
  margin-bottom: 4rem;
}
.testimonials {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin: 2rem 0 1rem;
}
.testimonial {
  background: var(--grey-light);
  color: var(--dark);
  padding: 20px 20px 0;
  line-height: 2.4rem;
  .stars {
    color: var(--brand);
    font-size: 3rem;
    margin-bottom: 7px;
  }
}
.codepen-embed {
  background: #eee;
  width: 100%;
}

.codepen__description {
  font-size: 16px;
  margin-bottom: 30px;
}

.stars {
  color: #fde16d;
  font-size: 3.6rem;
  letter-spacing: 3px;
}

.tags {
  display: flex;
}
.tags__tag {
  margin: 0 6px 6px 0;
  font-size: 14px;
  background: #555;
  padding: 3px 7px;
  border-radius: 3px;
  line-height: 1;
  color: var(--white);
  position: relative;
}

[data-reach-tab-list] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 0;
  margin-bottom: 40px;
}
[data-reach-tab] {
  font: inherit;
  display: flex;
  flex-direction: column;
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
  position: relative;
  &[data-selected='false']:hover {
    color: #000;
  }
}
.tab__text {
  font-size: 20px;
  padding: 5px 20px 10px;
}
.tab__underline {
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
}

// goes down slightly below hero banner section to cut the bottom off of the BrowserFrame component
.cropped-bottom {
  position: relative;
  bottom: -5px;
}

.icon-external-link {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.toggle {
  display: flex;
  justify-content: flex-start;
  width: 70px;
  border-radius: 40px;
  padding: 4px;
  background: var(--dark);
  overflow: hidden;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.toggle--toggled-on {
  justify-content: flex-end;
}
.toggle__nub {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  // day mode:
  background: linear-gradient(40deg, #ff7b00, #ffd900 70%);

  &::after {
    content: '';
    position: absolute;
    border-radius: 100%;
    top: 2px;
    right: -2px;
    width: 20px;
    height: 20px;
    background: var(--dark);
    transform: scale(0);
    transform-origin: top right;
    transition: transform 0.25s;
  }
}
.toggle__nub--toggled-on {
  //night mode
  background: linear-gradient(40deg, #3d5cab, #3ea8e7 70%);
  &::after {
    transform: scale(1);
  }
}
.toggle__checkbox {
  position: absolute;
  border: 0;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
}

@media (max-width: 799px) {
  .hide--up-to-md {
    display: none;
  }
}

@media (min-width: 420px) {
  .hero-video-wrap {
    transform: translateX(0);
  }
}
@media (min-width: 600px) {
  .col-2 {
    width: 50%;
  }

  .container {
    padding: 0 2em;
  }
  .card-wrap {
    &:hover {
      .card-desc {
        transform: translateX(-0.3em);
      }
      .card__heading {
        text-decoration: underline;
      }
      .fake-card-link {
        color: var(--brand);
      }
    }
  }
  .card-desc {
    padding-left: 2em;
    align-self: center;
    transition: all 0.2s ease-in-out;
  }
  .card-img {
    margin-bottom: 0;
  }

  .testimonials {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 800px) {
  .intro-wrap {
    margin-bottom: 60px;
  }
  .text-center-xs {
    text-align: left;
  }
  .home-intro {
    flex-direction: row;
  }
  .intro-img {
    align-self: flex-end;
    margin: 3em 0 0 0.5em;
  }
  .card-desc {
    padding-left: 4em;
  }
  .flex-50-wrap {
    flex-wrap: nowrap;
  }
  .flex-50 {
    width: 45%;
  }
}

@media (min-width: 1024px) {
  .grid-50 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
// @media (prefers-color-scheme: dark) {
//   body {
//     background-color: #000;
//     color: white;
//   }
// }

// Show focus styles on links/buttons etc for 'keyboard users' only
// This is facebook's approach.
// Found here: https://stackoverflow.com/a/51093815/1239334
body.using-mouse {
  :focus {
    outline: none !important;
  }
}
